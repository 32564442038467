import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Hartcoherentie from "../modules/specialisaties/Hartcoherentie"

const HartcoherentiePage = () => (
  <GlobalCollection>
    <Hartcoherentie />
  </GlobalCollection>
)

export default HartcoherentiePage
