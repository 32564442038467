import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import { Link } from "gatsby"
import HartCoherentieImg from "../../images/hartcoherentie.png"

const Depressie = () => (
  <DepressieWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Hartcoherentie</h1>
    </Flex>
    <Box p={3}>
      <StyledLink to="/specialisaties"> Terug</StyledLink>
      <TextBlock className="slanted centered">Adem jezelf rust in!</TextBlock>
      <TextBlock className="slanted centered">
        Hartcoherentie is de weg naar een gezonde en normale ademhaling.
      </TextBlock>
      <TextBlock>
        Het dagelijkse leven is voor velen hectisch en jachtig. Gezin en werk
        combineren is niet altijd makkelijk. Zolang je alles op een rijtje hebt,
        is het goed en gezond. Toch zien we hoe langer hoe meer mensen lijden
        onder langdurige stress, angsten en emotionele conflicten. Ontspanning
        is vaak iets wat op de laatste plaats komt. Er is onvoldoende psychisch
        en lichamelijk herstel waardoor men uitgeput geraakt.
        Concentratiestoornissen, hartkloppingen, hoge bloeddruk,
        slaapstoornissen, depressieve gevoelens, darmklachten, opgejaagdheid,
        moeheid, lage weerstand, een onbehaaglijk gevoel, prikkelbaarheid, té
        emotioneel zijn en burn-out, kunnen hiervan de gevolgen zijn. Dit zijn
        de gevolgen van ons autonoom zenuwstelsel dat uit balans is.
      </TextBlock>
      <TextBlock>
        De enige weg waarop we het autonoom zenuwstelsel kunnen beïnvloeden, is
        via de ademhaling. Door de ademtechniek hartcoherentie toe te passen
        merk je dat je je gezonder, minder gestrest en rustiger gaat voelen.
      </TextBlock>
      <TextBlock>
        Herken jij jezelf in één of meerdere bovenvernoemde symptomen? Wil je
        jezelf terug in balans brengen? Dan is hartcoherentie de oplossing voor
        jou!
      </TextBlock>
      <h2>Hoe werkt hartcoherentie?</h2>
      <TextBlock>
        Hartcoherentie is een wetenschappelijk onderbouwde methode. In een
        notendop: het hart brengt het autonoom zenuwstelsel tot rust en de
        ademhaling brengt het hart tot rust.
      </TextBlock>
      <TextBlock>
        Jouw hartrimte wordt bepaald door emoties, gedachten en je ademhaling.
        Negatieve- of angstige gedachten en emoties doen jouw hartslag
        versnellen. Dit brengt je uit evenwicht. Het is nu net via bewuste
        ademhaling dat je terug balans kan vinden. Deze ademtechniek brengt het
        hart in een bijzondere staat. Verscheidene mechanismen in het lichaam
        gaan elkaar dan versterken zodanig dat er een harmonische werking
        ontstaat tussen het brein, het hart en de bloeddruk, ademhaling en
        hormoonregeling. Door slechts enkele minuten per dag deze methode toe te
        passen kom je in een kwalitatieve herstelfase met een 6 uur durend
        gunstig effect op lichaam en geest!
      </TextBlock>
      <StyledImg src={HartCoherentieImg} alt="hartcoherentie" />
      <TextBlock>
        In bovenstaande figuur zie je de blauwe golf, dat is de ademhaling en de
        rode is die van de hartritme variatie. De rode vierkantjes stellen de
        hartslagen voor.
      </TextBlock>
      <TextBlock>
        De eerste minuut zie je een oppervlakkige ademhaling en een snel
        veranderend hartritme. Na twee keer lang en rustig uitademen zie je een
        veel rustiger patroon bij het hartritme. Het hartritme versnelt en daalt
        gelijkmatig met de ademhaling. Er komt dus meer rust in het systeem van
        het hart en ook meer rust in het systeem van het autonome zenuwstelsel.
        De adem is de sleutel om deze rust te verkrijgen.
      </TextBlock>
      <h2>Effecten van hartcoherentie-training:</h2>
      <ul>
        <li>Herkennen van innerlijke stressbeleving</li>
        <li>Verbetering van concentratie en focus</li>
        <li>Bevordering van creatief denken</li>
        <li>Bevordering van rustgevoel en welbehagen</li>
        <li>Verhogen van relativerend vermogen</li>
        <li>Verhoging van de veerkracht t.o.v. stress</li>
        <li>Verandering van gedrag en denken in positieve zin</li>
        <li>
          Bloeddruk regulerend, daling van hoge  bloeddruk (stress gerelateerd).
        </li>
        <li>Effect op hartritmestoornissen (stressgerelateerd)</li>
        <li>Verhoging van weerstand tegen ziekten</li>
        <li>Beheersing van angsten</li>
        <li>Ondersteunend effect bij regulering van  bloedsuiker  waarden</li>
        <li>Toename van energie</li>
        <li>Toename van performantie</li>
        <li>Regulering van lichaamsgewicht ( bij emo-eten)</li>
      </ul>
      <h2>Praktisch:</h2>
      <TextBlock>
        Tijdens de individuele sessies krijg je inzichten in hoe je lichaam
        werkt. Daarenboven is het doel van deze training om in de verdere
        toekomst zelfstandig de oefeningen te kunnen uitoefenen. Iedere sessie
        bestaat uit het meegeven van informatie en de oefeningen. Stelselmatig
        en op jouw tempo worden de oefeningen opgebouwd en ingeoefend. De
        ademtechniek is niet moeilijk, vraagt wel om een dagelijkse discipline.
        De beloning is groot!
      </TextBlock>
      <ul>
        <li>
          Sessie 1 (1,5u): Intake. Hoe werkt jouw zenuwstelsel? Wat doet stress
          met jou? Wat is de invloed van ademhaling? Hoe kan je “onthaasten”?
          Bewust leren ademen. Informatie over (gratis) apps die je helpen
          ritmisch te ademen. Eerste meting met biofeedback apparatuur.
          Thuisopdracht.
        </li>
        <li>
          Sessie 2 (1u): Hoe ging het de voorbije 2 weken? Hoe kan je jezelf in
          balans brengen? Wat is hartcoherentie en de functie van het hart? Hoe
          kan je thuis zelfstandig meten of het zenuwstelsel in balans is?
          Tweede meting met biofeedback apparatuur. Thuisopdracht.
        </li>
        <li>
          Sessie 3 (1u): Ervaring bevragen van voorbije periode. Wat is de rol
          van hormonen en wat is de invloed van emoties? Hoe kan je een betere
          emotionele beheersing bekomen en veerkracht opbouwen? Derde meting met
          biofeedack apparatuur. Thuisopdracht.
        </li>
        <li>
          Sessie 4 (1u): Eindevaluatie dmv vragenlijst van 4 domeinen:
          lichamelijke klachten, negatieve stress, depressie en angst.
          Hartcoherentie in je dagdagelijkse leven: tips en tricks bij acute en
          toekomstige stress, conflicten, faalangst,…. Werken aan een emotionele
          huishouding. Laatste meting met biofeedback apparatuur.
        </li>
      </ul>

      <TextBlock>
        Tussen elke sessie is er een tijd van ongeveer 2 weken zodat je de
        aangeleerde ademtechniek kan oefenen.
      </TextBlock>
      <h2>Vraagt hartcoherentie oefenen veel tijd?</h2>
      <TextBlock>
        Je kan 3 maal per dag 6 minuten oefenen of 2 maal 10 minuten. Deze
        deelname vraagt weinig van je dagdagelijkse tijd, maar wel aandacht en
        discipline voor de beoefening van hartcoherentie. Deze korte tijd geeft
        je een kwalitatieve herstelfase met een 6 uur durend gunstig effect op
        lichaam en geest!
      </TextBlock>
      <TextBlock>
        Indien gewenst, kan je bijkomende vragen stellen of een afspraak maken
        via het contactformulier.
      </TextBlock>
    </Box>
  </DepressieWrapper>
)

export default Depressie

const DepressieWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
  display: block;
  margin-bottom: 25px;
  font-size: 1.1em;
`

const StyledImg = styled.img`
  width: 500px;
  max-width: 90%;
  margin: 25px 0;
`
